// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-projectpage-js": () => import("./../../src/components/projectpage.js" /* webpackChunkName: "component---src-components-projectpage-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contactme-js": () => import("./../../src/pages/contactme.js" /* webpackChunkName: "component---src-pages-contactme-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */)
}

